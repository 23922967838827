var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"product-add-wrapper"},[_c('b-row',{staticClass:"product-add"},[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-card',{staticClass:"product-preview-card"},[_c('b-card-body',{staticClass:"product-padding pt-0"},[_c('b-row',{staticClass:"product-spacing"},[_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","xl":"6"}},[_c('h6',{staticClass:"mb-2"},[_vm._v("المنتج :")]),_c('validation-provider',{attrs:{"name":"المنتج","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"المنتج","label-for":"variation_id"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'rtl',"options":_vm.mainProducts,"load":_vm.mainProducts.length > 0 ? false : true,"reduce":function (label) { return label.value; },"clearable":false},on:{"input":function (val) { return _vm.updateItemForm(val); }},model:{value:(_vm.productData.variation_id),callback:function ($$v) {_vm.$set(_vm.productData, "variation_id", $$v)},expression:"productData.variation_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-card-body',{staticClass:"product-padding form-item-section"},[_c('div',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight })},[_c('b-row',{staticClass:"pb-2"},_vm._l((_vm.productData.items),function(item,index){return _c('b-col',{key:index,ref:"row",refInFor:true,attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex border rounded mb-1"},[_c('div',[_c('b-row',{staticClass:"flex-grow-1 pl-1 pr-1 pb-0"},[_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('label',{staticClass:"w-100 text-center",attrs:{"align":"center"}},[_c('strong',[_vm._v("الكمية")])])]),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"الكمية  ","vid":("items[" + index + "].from_qty"),"rules":{
                                required: true,
                                min_value: 1,
                                numeric: true,
                                regex: /^([0-9]+)$/
                              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var valid = ref.valid;
                              var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":" من"}},[_c('b-form-input',{staticClass:"mb-2",attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(item.from_qty),callback:function ($$v) {_vm.$set(item, "from_qty", $$v)},expression:"item.from_qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"الكمية  ","vid":("items[" + index + "].to_qty"),"rules":{
                                required: true,
                                min_value: 1,
                                numeric: true,
                                regex: /^([0-9]+)$/
                              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var valid = ref.valid;
                              var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":" الى"}},[_c('b-form-input',{staticClass:"mb-2",class:{ 'is-invalid': !!errors.length },attrs:{"type":"number","no-wheel":"","state":errors[0] ? false : valid ? true : null},model:{value:(item.to_qty),callback:function ($$v) {_vm.$set(item, "to_qty", $$v)},expression:"item.to_qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1)],1),_c('b-row',{staticClass:"flex-grow-1 pl-1 pr-1"},[_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('validation-provider',{attrs:{"name":"السعر","vid":("items[" + index + "].price"),"rules":{
                                required: true,
                                min_value: 3,
                                regex: /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/
                              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":" السعر"}},[_c('b-form-input',{staticClass:"mb-2",attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"الحالة","vid":("items[" + index + "].status"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":" الحالة"}},[_c('b-form-checkbox',{attrs:{"id":(index + "status"),"checked":item.status,"state":errors.length > 0 ? false : null,"switch":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex flex-column justify-content-between border-left py-50 px-25"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"size":"16","icon":"XIcon"},on:{"click":function($event){return _vm.removeItem(index)}}})],1)])])}),1)],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.addNewItemInItemForm}},[_vm._v(" اضافة جديد ")])],1)],1),_c('b-card',[_c('b-row',{staticClass:"product-spacing"},[_c('b-col',{staticClass:"mb-lg-1 m-auto",attrs:{"cols":"12","xl":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"primary","type":"submit","block":""}},[_vm._v(" حفظ ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }